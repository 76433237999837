.app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.app.light {
  --theme-color: var(--light-theme-color);
  background-color: #F2F2F2;
}

.app.dark {
  --theme-color: var(--dark-theme-color);
  background-color: black;
}

:root {
  --light-theme-color: black;
  --dark-theme-color: white;
}
  
  .theme-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
  }
  
  h1, p, .user-input {
    color: var(--theme-color);
  }
  
  .user-input {
    width: 90%;
    height: calc(100vh - 250px); 
    padding: 10px;
    margin: 20px auto;
    display: block;
    resize: none;
    overflow-y: auto;
    background: transparent;
    font-size: 1.3em;
    outline: none;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }

  .timer {
    font-size: 2em; 
    border-bottom: 2px solid var(--theme-color);
    padding-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); 
    color: white;
    font-size: 2em;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
  
  .prompt {
    color: white;
    margin-bottom: 20px;
    border-bottom: 2px solid white;
  }

  .overlay .prompt-container p {
    color: white;
  }

  .timer-container {
    color: var(--theme-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .prompt-title {
    text-align: center;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  .start-typing {
    font-size: 0.7em;
    color: white;
  }

  .email-prompt-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .email-prompt {
    width: 80%;
    max-width: 500px;
    padding: 20px;
    background-color: transparent;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1.5em;
  }
  
  .email-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .email-submit {
    width: 40%;
    padding: 10px;
    cursor: pointer;
    font-family: 'Dokdo', cursive;
    font-size: 1.2em;
    filter: drop-shadow(-5px 5px 4px grey);
    border-radius: 10px;
  }

  .start-button {
    position: relative;
    z-index: 10;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 20px;
    color: black;
    background: white;
    border: none;
    border-radius: 5px;
    margin-top: -30px;
  }

  .app-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }
  
  
  
  
  
  
  
  